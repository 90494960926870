import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'

import { deleteOrder } from '@services/orderservice'

import {
  Button,
  Grid,
  Typography,
  Card,
  CardContent,
  CardActions,
  Toolbar,
  Paper,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(4),
    minWidth: '100%',
  },
  card: {
    margin: theme.spacing(12, 0),
    width: '100%',
  },
  orderpaper: {
    padding: theme.spacing(6),
    marginBottom: theme.spacing(2),
  },
  ordercardwrapper: {
    marginTop: theme.spacing(2),
  },
  info: {
    fontSize: '16px',
    lineHeight: '1.4',
    color: theme.palette.primary.contrastText,
  },
  message: {
    margin: theme.spacing(4, 2),
  },
}))

function OrderCard({ onActionClick, order }) {
  const classes = useStyles()

  console.log('order: ', order)

  function renderOrderCards() {
    return order.products.map((product) => (
      <Paper key={product.title} className={classes.orderpaper} elevation={0}>
        <Grid container spacing={2}>
          <Grid item>{product.amount}x</Grid>
          <Grid item>{product.variant} - </Grid>
          <Grid item>{product.title}</Grid>
        </Grid>
      </Paper>
    ))
  }

  return (
    <Card elevation={3} className={classNames(classes.card)}>
      <Toolbar className={classes.toolbar}>
        <Grid
          container
          direction="row"
          spacing={1}
          wrap="nowrap"
          justify="space-between"
          alignContent="flex-end"
        >
          <Grid item container direction="column" xs={8} spacing={0}>
            <Grid item container spacing={2}>
              <Grid item>
                <Typography className={classes.info}>
                  {order.firstname}
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.info}>
                  {order.lastname}
                </Typography>
              </Grid>
            </Grid>
            {!!order.department && (
              <Grid item>
                <Typography className={classes.info}>
                  {order.department}
                </Typography>
              </Grid>
            )}
            {!!order.phone && (
              <Grid item>
                <Typography className={classes.info}>{order.phone}</Typography>
              </Grid>
            )}
            <Grid item>
              <Typography className={classes.info}>{order.email}</Typography>
            </Grid>
          </Grid>
          <Grid item container direction="column" alignItems="flex-end">
            {!!order.company && (
              <Grid item>
                <Typography className={classes.info}>
                  {order.company}
                </Typography>
              </Grid>
            )}
            <Grid item>
              <Typography className={classes.info}>
                {order.streetAndNumberOrPoBox}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.info}>
                {order.postcodeAndCity}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container direction="column" alignItems="flex-end">
            <Grid item>
              <Typography className={classes.info}>
                Angefragt am{' '}
                {moment(order.timestamp)
                  .locale('de')
                  .format('DD.MM.YYYY [um] HH:mm [Uhr]')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
      <CardContent>
        <Grid
          className={classes.ordercardwrapper}
          container
          wrap="nowrap"
          spacing={2}
          direction="column"
        >
          {!!order.message && (
            <Typography
              className={classes.message}
              dangerouslySetInnerHTML={{ __html: order.message }}
            />
          )}
          {renderOrderCards()}
        </Grid>
      </CardContent>
      <CardActions>
        <Grid container justify="flex-end" spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                deleteOrder(order.id).then(() => {
                  if (onActionClick) onActionClick()
                })
              }}
            >
              <FormattedMessage id="button.deleterequest" />
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  )
}

OrderCard.propTypes = {
  onActionClick: PropTypes.func,
  order: PropTypes.any,
}

export default OrderCard
